@import './modern-react-select-theme.scss';
@import './modern-sweetalert-theme.scss';

html .qty-picker-btn {
  color: #090216;
}

html .btn-primary {
  background-color: #7969c1;
  border-color: #7969c1;
  color: #ffffff;
  text-decoration: none;
}

html .btn-primary:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #3B2B4F;
  border-color: #3B2B4F;
}

html .btn-primary.disabled,
.btn-primary:disabled {
  color: #999ba7;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

html .btn-danger {
  background-color: #f36e6c;
  border-color: #f36e6c;
  color: #ffffff;
}

html .btn-outline-danger {
  background-color: #ffffff;
  border-color: #f36e6c;
  color: #f36e6c;
}

html .btn-outline-danger:hover {
  background-color: #f36e6c;
  border-color: #f36e6c;
  color: #ffffff;
  text-decoration: none;
}

html .btn-link:hover {
  color: #3B2B4F;
  text-decoration: underline;
}

html .btn-outline-primary:hover {
  background-color: #3B2B4F;
  border-color: #3B2B4F;
  color: #ffffff;
  text-decoration: none;
}

html .btn-outline-primary {
  color: #7969c1;
  border-color: #7969c1;
}

html .btn-outline-primary:disabled {
  color: #7969c1;
  background-color: #b8bac5;
  border-color: #b8bac5;
}

html .btn-primary.focus,
.btn-primary:focus {
  color: #ffffff;
  background-color: #7969c1;
  border-color: #7969c1;
}

html .btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #7969c1;
  border-color: #7969c1;
}

html .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #7969c1;
  border-color: #7969c1;
}

html .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

html .btn:focus {
  outline: none;
  box-shadow: none;
}

html .btn-link {
  color: #7969c1;
  text-decoration: underline;
}

html .cd-breadcrumb {
  color: #090216;
  text-decoration: none;
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
    color: inherit; /* Inherit the color from the breadcrumb item */
    font-weight: bold;
    
  }
  a {
    font-weight: bold; /* Make the breadcrumb links bold */
    color: #090216;
    text-decoration: none;
    font-size: 1rem;
  }
  a:hover {
    color: #090216;
    text-decoration: underline;
    font-size: 1rem;
  }
}

html .cd-breadcrumb:hover {
  color: #090216;
  text-decoration: underline;
}

html .nav-menu-btn {
  font-weight: 500;
  color: #090216;
  text-decoration: none;
  cursor: pointer;
}

html .nav-menu-btn:hover {
  color: #3B2B4F;
  text-decoration: underline;
}

html .nav-back-btn {
  font-weight: 500;
  color: #090216;
  text-decoration: none;
  cursor: pointer;
}

html .nav-back-btn:hover {
  color: #BEDC00;
  text-decoration: underline;
}

html .text-primary {
  color: #090216 !important;
}

html .modal-content {
  border-radius: 0.5rem;
}

html .text-chartreuse {
  color: #dcfe07 !important;
}

html a {
  color: #474150;
  text-decoration: underline;
}

html a:hover {
  color: #3B2B4F;
  text-decoration: underline;
}

/* nav-link element overrides */
html .nav-link,
.list-group-item,
a.btn {
  text-decoration: none;
}

html .nav-tabs .themed_tabs.nav-link {
  border: none;
  border-bottom: 2px solid #e7eaec;
}

html .nav-tabs .themed_tabs.nav-link.active {
  border-bottom: 2px solid #090216;
  font-weight: bold;
}

/* form element overrides */
html .form-control:focus {
  box-shadow: 0 0 2px 1px #7969c1;
  border-color: #e7eaec;
}

html .form-control::-webkit-input-placeholder {
  color: #bbbbbb;
}

html .form-label {
  font-weight: bold;
}

/* Nav-pills override classes */
html .nav-pills .nav-link.active {
  background-color: #7969c1;
}

/* React-select override classes */
html .themed_select__option--is-focused {
  background-color: #7969c1 !important;
  color: #090216 !important;
}

html .themed_select__option--is-selected,
.themed_select__option--is-selected:hover {
  background-color: #090216 !important;
  color: #ffffff !important;
}

html .themed_select__control--is-focused,
.themed_select__control--menu-is-open {
  box-shadow: none;
  border-color: #cccccc;
}

html .themed_select__control:hover,
.themed_select__control:active,
.themed_select__control:visited,
.themed_select__control:focus,
.themed_select__control:focus-visible {
  box-shadow: none;
  border-color: #cccccc;
}

html .themed_select__indicators,
.themed_select__dropdown-indicator {
  color: #090216 !important;
}

/* fontawsome checkbox color override */
html .fa-check-square.text-primary {
  color: #090216 !important;
}

html .dropdown-item:hover {
  background-color: #7969c1;
  color: #ffffff;
}

html .dropdown-item.active {
  background-color: #ffffff;
  color: #7969c1;
}

@media only screen and (max-width: 1600px) and (min-width: 768px) {
  body {
    font-size: 0.875rem;
  }
  html .btn-link {
    font-size: 0.875rem;
  }
  html .h2,
  h2 {
    font-size: 1.75rem;
  }
}

/* for highlighting child icons on parent hover */
.child-item-secondary {
  color: #7969c1;
}

.parent-item:hover .child-item-secondary {
  color: #090216;
}

.child-item-primary {
  background-color: #7969c1;
  color: #090216;
}

.parent-item:hover .child-item-primary {
  background-color: #090216;
  color: #7969c1;
}

.parent-item.active .child-item-primary {
  background-color: #090216;
  color: #7969c1;
}

.parent-item:hover .child-item-underline {
  text-decoration: underline;
}

html .font-weight-900 {
  font-weight: 900;
}

html .h2,
h2,
.h6,
h6 {
  font-family: 'Mont', sans-serif !important;
}

body {
  font-family: 'Lato', sans-serif;
}

html .modern-color-icon {
  --fa-primary-color: #090216;
  --fa-secondary-color: #dcfe07;
  --fa-secondary-opacity: 1;
  --fa-primary-opacity: 1;
}

html .modern-square-button,
.modern-square-button:focus {
  color: #090216;
  background-color: #ffffff;
  border: 1px solid #090216;
}

html .modern-square-button:hover {
  color: #dcfe07;
  background-color: #090216;
  border: 1px solid #090216;
}

html .modern-square-button.disabled,
.modern-square-button:disabled {
  color: #999ba7 !important;
  background-color: #ffffff !important;
  border: 1px solid #999ba7 !important;
}

html .tooltip {
  background-color: #090216 !important;
}

html .progress {
  height: 1.7rem;
  background-color: #ffffff;
  border: 1px solid #e7eaec;
}
html .progress-bar {
  color: #090216;
  background-color: #7969c1;
}

html .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.45) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.45) 50%,
    rgba(255, 255, 255, 0.45) 75%,
    transparent 75%,
    transparent
  );
}

/* ---- begin slick ---- */

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;
  color: black;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: black;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  color: rgb(220, 254, 7);
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 50px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  font-size: 80px;
  color: rgb(220, 254, 7);
}

/* ---- end slick ---- */

/* ---- start datepicker ---- */
.react-datepicker__day:hover {
  background: #dcfe0780 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background: #090216 !important;
}
.react-datepicker__day--keyboard-selected {
  background: #090216bd !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-range:hover {
  background: #09021680 !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #09021680 !important;
}
.react-datepicker__close-icon {
  right: -30px !important;
}
.react-datepicker__close-icon::after {
  background-color: #09021680 !important;
}
/* ---- end datepicker ---- */

/* ---- Pagination ---- */
.page-item {
  font-size: '20px' !important;
}
.page-item a {
  color: #090216 !important;
  text-decoration: none !important;
}
.page-item.active .page-link {
  background-color: #7969c1;
  border-color: #7969c1;
  color: #ffffff;
}
/* ---- end Pagination ---- */

/* ---- custom checkbox styles ---- */
html input.modern-input + div.checked {
  font-weight: bold;
}

html .carousel-indicators li {
  background-color: #f1f2f5;
}

html .carousel-indicators .active {
  background-color: #bedc00;
}

/* ---- start Tabs ---- */

html nav.nav.nav-tabs a.nav-item.nav-link.active {
  border: 0;
  border-bottom: 3px solid #9E8CFF;
  font-weight: bold;
  color: #9E8CFF;
}

/* ---- end Tabs ---- */
