body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
  font-size: 0.8rem !important;
  padding: 0.25rem 0.5rem !important;
}

.pagination .page-link {
  padding: 0.25rem 0.5rem !important;
}

.Pane {
  overflow-y: inherit !important;
}
