/* Replacing inbuilt icons and disabling animations */

.swal2-icon {
  border: 0;
  height: 6em;
  width: 6em;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
}

.swal2-icon.swal2-success {
  background-color: #9e8cff;
  background-image: url('../../../src/images/icons/modal/check-circle.png');
}

.swal2-icon.swal2-error {
  background-color: #f3636c;
  background-image: url('../../../src/images/icons/modal/x.png');
}

.swal2-icon.swal2-warning {
  background-color: #f3636c;
  background-image: url('../../../src/images/icons/modal/alert-triangle.png');
}

.swal2-icon.swal2-question {
  background-color: #9e8cff;
  background-image: url('../../../src/images/icons/modal/help-circle.png');
}

.swal2-icon.swal2-info {
  background-color: #9e8cff;
  background-image: url('../../../src/images/icons/modal/info.png');
}

.swal2-icon.swal2-info.swal2-alt {
  background-color: #007db2;
  background-image: url('../../../src/images/icons/modal/info-white.png');
}

.swal2-icon .swal2-icon-content {
  display: none;
}

.swal2-success-line-tip,
.swal2-success-line-long,
.swal2-success-ring,
.swal2-success-fix,
.swal2-success-circular-line-right,
.swal2-success-circular-line-left,
.swal2-icon.swal2-success [class^='swal2-success-line'],
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  display: none;
}

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: none;
  animation: none;
}

/*-----------------------------------------------------------------------*/
/*Formatting buttons*/

.swal2-actions button.swal2-styled {
  box-shadow: none;
  border-radius: 0.7em;
}

.swal2-styled.swal2-confirm {
  background-color: #9e8cff;
  color: #090216;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #b9aef5;
  color: #090216;
}

.swal2-styled.swal2-confirm.swal2-outline-primary {
  background-color: white;
  color: #090216;
  border: 1px solid #090216;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 50px;
}

.swal2-styled.swal2-confirm.swal2-outline-primary {
  background-color: #9e8cff;
  color: #090216;
  border: 1px solid #9e8cff;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 50px;
}

:is(.swal2-icon-error, .swal2-icon-warning) .swal2-styled.swal2-confirm {
  background-color: #f3636c;
  color: #fff;
}

:is(.swal2-icon-error, .swal2-icon-warning) .swal2-styled.swal2-confirm:hover {
  background-color: rgb(243, 99, 108, 0.5);
  color: #fff;
}

:is(.swal2-icon-success, .swal2-icon-info, .swal2-icon-question) .swal2-styled.swal2-confirm {
  background-color: #9e8cff;
  color: #090216;
}

:is(.swal2-icon-success, .swal2-icon-info, .swal2-icon-question) .swal2-styled.swal2-confirm:hover {
  background-color: #b9aef5;
  color: #090216;
}

:is(.swal2-icon-success, .swal2-icon-info, .swal2-icon-question)
  .swal2-styled.swal2-confirm.swal2-outline-primary {
  background-color: white;
  color: #090216;
  border: 1px solid #090216;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 50px;
}

:is(.swal2-icon-success, .swal2-icon-info, .swal2-icon-question)
  .swal2-styled.swal2-confirm.swal2-outline-primary:hover {
  background-color: #9e8cff;
  color: #090216;
  border: 1px solid #9e8cff;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 50px;
}

.swal2-styled.swal2-cancel {
  border: 1px solid #090216;
  background-color: #fff;
  color: #090216;
}

.swal2-styled.swal2-cancel:hover {
  border: 1px solid #9e8cff;
  background-color: #9e8cff;
  color: #090216;
}

.swal2-styled.swal2-cancel:disabled {
  color: #999ba7;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

.swal2-styled.swal2-cancel:disabled .swal2-styled.swal2-cancel:hover {
  cursor: none;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none;
}

/*-----------------------------------------------------------------------*/
/* Modal formatting */

.swal2-close {
  font-size: 2em;
}

.swal2-popup {
  border-radius: 0.9em;
}

.swal2-title {
  color: #090216;
  font-family: 'Mont';
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 0.6em;
}
.required-field::after {
  content: '*';
  color: red;
}
.required-field-before::before {
  content: '*';
  color: red;
}
