/* React-select override classes not to colors */
html .themed_select__indicator-separator {
  display: none;
}

html .themed_select__indicator {
  padding-left: 4px;
}

html .themed_select__value-container {
  padding-right: 4px;
}

@media (max-width: 575px) {
  html .themed_select__indicator svg {
    width: 16px !important;
    height: 16px !important;
  }

  html .themed_select__indicator {
    padding-left: 2px;
    padding-right: 6px;
  }

  html .themed_select__value-container {
    padding-left: 6px;
    padding-right: 2px;
  }

  html .themed_select__control * {
    font-size: 14px;
  }
}
